








import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import MentorExercisesTable from '@/components/tables/MentorExercisesTable.vue'

@Component({
  components: {
    MentorExercisesTable,
  },
})
export default class ExercisesList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Домашняя работа',
    }
  }
}

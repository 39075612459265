


































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import DateInput from '@/components/_uikit/controls/DateInput.vue'
import MasterGroupsSearch from '@/components/_uikit/search/MasterGroupsSearch.vue'
import MentorExerciseCard from '@/components/cards/MentorExerciseCard.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SelectExerciseTemplateModal from '@/components/modals/exercise/SelectExerciseTemplateModal.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  CourseType,
  ExerciseManagerCardResource,
  ManagerMasterGroupsMasterGroupIdExercisesGetParams,
} from '@/store/types'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import MentorEducationModule from '@/store/modules/mentor/education'

enum MenuActions {
  CREATE = 'create',
  JOURNAL = 'journal',
  TEMPLATE = 'template',
  TEMPLATE_MULTIPART = 'template_multipart',
}

@Component({
  components: {
    DateInput,
    MasterGroupsSearch,
    MentorExerciseCard,
    Parameters,
    Select,
    SelectExerciseTemplateModal,
    TableFooter,
    TextInput,
  },
})
export default class MentorExercisesTable extends Mixins(MasterGroupMixin, PermissionsMixin) {
  private showSearchExerciseModal = false
  private multipartTemplateMode = false

  private get filter() {
    return MentorExercisesModule.exercisesFilter
  }

  private set filter(filter: ManagerMasterGroupsMasterGroupIdExercisesGetParams) {
    MentorExercisesModule.setExercisesFilter(filter)
    this.fetchExercises()
  }

  private get exerciseStatuses () {
    return DictionaryModule.exerciseStatusesFilter
  }

  private get filterString () {
    return JSON.stringify(this.filter)
  }

  private get exercises () {
    return MentorExercisesModule.exercises
  }

  private get menu () {
    const _menu = [
      { name: 'Журнал оценок', value: MenuActions.JOURNAL },
      { name: 'Выбрать одиночное задание', value: MenuActions.TEMPLATE },
      { name: 'Выбрать составное задание', value: MenuActions.TEMPLATE_MULTIPART },
    ]

    return _menu
  }

  private get selectedMonth(): string {
    if (this.filter.monthId) {
      return this.currentMasterGroup.months.find(month => +month.value === this.filter.monthId)?.name || ''
    }
    return ''
  }

  private mounted () {
    if (this.currentMasterGroupMonth || this.filter.monthId) {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number,
      }
    } else {
      this.$bus.$on('fetchMasterGroups', this.fetchMasterGroups)
      //this.fetchExercises()
    }
  }

  private destroyed() {
    this.$bus.$off('fetchMasterGroups', this.fetchMasterGroups as any)
  }

  private fetchMasterGroups() {
    MentorEducationModule.fetchMasterGroups(true)
      .then(() => {
        this.fetchExercises()
      })
  }

  private getRedirectData (exercise: ExerciseManagerCardResource) {
    if (exercise.course.type.value !== CourseType.SPECIAL) {
      if (exercise.tasks.length) {
        return {
          name: 'manager.education.exercises.item.masterList',
          params: {
            exerciseUUID: exercise.uuid,
            groupID: this.currentMasterGroupID,
            taskUUID: exercise.tasks[0].uuid,
          },
        }
      }
      return { name: 'manager.education.exercises.item.edit', params: { groupID: this.currentMasterGroupID.toString(), exerciseUUID: exercise.uuid } }
    } else {
      return {
        name: 'manager.education.exercises.quickStart',
        params: {
          exerciseUUID: exercise.uuid,
          groupID: this.currentMasterGroupID,
        },
      }
    }
  }

  private handleFilter(field: string, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
    }

    if (field === 'monthId') MentorEducationModule.setSavedMasterGroupMounthID(value)
  }

  @Bind
  @Debounce(500)
  private fetchExercises () {
    MentorExercisesModule.fetchExercises({
      masterGroupId: this.currentMasterGroupID,
      params: this.filter,
    })
      .catch(this.notifyError)
  }

  private handleJournal () {
    this.$router.push({ name: 'manager.education.exercises.journal' })
  }

  private handleMenuItemClick (action: MenuActions) {
    switch(action) {
    case MenuActions.TEMPLATE_MULTIPART:
      this.multipartTemplateMode = true
      this.showSearchExerciseModal = true
      break
    case MenuActions.TEMPLATE:
      this.multipartTemplateMode = false
      this.showSearchExerciseModal = true
      break
    case MenuActions.JOURNAL:
      this.handleJournal()
      break
    }
  }

  private handleAddExercise (exerciseUUID: string) {
    this.$router.push({
      name: 'manager.education.exercises.item.edit',
      params: {
        exerciseUUID: exerciseUUID,
        groupID: this.currentMasterGroupID.toString(),
      },
    })
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID() {
    MentorEducationModule.setSavedMasterGroupMounthID(null)

    if (this.currentMasterGroupMonth) {
      this.filter = {
        monthId: this.currentMasterGroupMonthID as number,
      }
    }
  }
}
